<template>
  <div
    class="cms-media-html"
    :class="classnames"
    v-html="value"
  ></div>
</template>

<script>
export default {
  name: "cms-media-html",
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },

    theme: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    classnames() {
      if (!this.theme) {
        return null;
      }
      return '--theme-' + this.theme;
    }
  }
};
</script>

<style lang="scss">
@import "./themes/index.scss";
</style>